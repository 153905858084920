* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

header {
  background-color: #f2f2f2;
  padding: 1rem;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

li {
  margin: 0 1rem;
}

button {
  background-color: #61dafb;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  cursor: pointer;
}


/* Chatbot UI below */

.grid-container {
  display: grid;
  grid-template-areas:
    'left main main main main right'
    'left main main main main right'
    'left main main main main right'
    'left main main main main right'
    'left main main main main right'
    'left main main main main right'
    'left main main main main right'
    'left footer footer footer footer right';
  gap: 10px;
  background-color: #EAFFCE;
  padding: 10px;
  width: 100vw;
  height: 100vh;
}

.grid-container>div {
  text-align: center;
  padding: 20px 0;
  font-size: 15px;
}

.chat-history {
  background-color: #EAFFCE;
  grid-area: main;
  overflow: scroll;
  overflow-x: hidden;
}

.chat-history-left {
  grid-area: left;
  background-color: #EAFFCE;
}

.chat-history-right {
  grid-area: right;
  background-color: #EAFFCE
}

.input-container {
  grid-area: footer;
  background-color: rgba(255, 255, 150, 0.8);
  display: flex;
  align-items: stretch;
  height: 80px;
}

.input-field {
  flex-grow: 9;
}

.send-button {
  flex-grow: 1;
}

/* Chatbot UI above */


.clockContainer {
  position: relative;
  margin: auto;
  height: 10vw; /* make the height and width responsive */
  width: 10vw;
  background: url(analog-clock-frame.jpg)
    no-repeat;
  background-size: 100%;
}

.hour,
.minute,
.second {
  position: absolute;
  background: black;
  border-radius: 10px;
  transform-origin: bottom;
}

.hour {
  width: 1.8%;
  height: 25%;
  top: 25%;
  left: 48.85%;
  opacity: 0.8;
}

.minute {
  width: 1.6%;
  height: 30%;
  top: 19%;
  left: 48.9%;
  opacity: 0.8;
}

.second {
  width: 1%;
  height: 40%;
  top: 9%;
  left: 49.25%;
  opacity: 0.8;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto; /* add margin and change width to 100% */
  width: 100%;
  max-width: 500px; /* add max-width for better responsiveness */
  padding: 20px; /* add padding for spacing */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* add box shadow for visual separation */
  border-radius: 5px; /* add border radius for rounded corners */
}

.form-input {
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 500px;
}

/* Signup UI below */

.signup-input {
  height: 48px;
  width: 440px;
  font-size: 40px;
}

.signup-button {
  display: block;
  margin-top: 12px;
  height: 48px;
  font-size: 24px;
}

.signup-label {
  display: block;
  margin-top: 8px;
  font-size: 48px;
}

/* Signup UI above */